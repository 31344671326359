import './GetStarted.css'
import {useState} from 'react'
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import HeaderImage1 from '../../../Assets/Home/HeaderImage1.png'
import GetStartedImage1 from '../../../Assets/Home/GetStartedImage1.png';
import GetStartedImage2 from '../../../Assets/Home/GetStartedImage2.png';


const GetStarted = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return(
        <div className="getStarted-main" >

            {/* Navigation and address area */}
            <div className='getStarted-nav flex' >
                {/* Social Link */}
                <div className='getStarted-nav-social basis-1/4' >

                    <div className='getStarted-nav-social-p1' >
                        <div className='social-container-1' ><XIcon style={{color: 'white'}}/></div>
                        <div className='social-container-1' ><TelegramIcon style={{color: 'white'}}/></div>
                        <p>Join us! &nbsp; </p>
                    </div>

                    <div className='getStarted-nav-social-p1 getStarted-nav-social-p2' >
                        <p>&nbsp; Whitepaper</p>
                        <div className='social-container-2' ><ArrowOutwardIcon style={{color: 'white'}}/></div>
                    </div>
                </div>

                {/* Contract address */}
                <div className='getStarted-nav-contract basis-1/2' >
                    
                    <div className='getStarted-nav-contract-head' >
                        <img className='' src={HeaderImage1} alt='HeaderImage1'  />
                        <p className=''>Hamster Pilot</p>
                    </div> 


                    <div className='getStarted-nav-contract-address' >
                        <div className='getStarted-nav-contract-address-a1' >
                            <h4>Contract Address</h4>
                            <h2>0x7ff5AeD25388FECA5a89844DCBAf97a022177e94</h2>
                        </div>
                        <div className='getStarted-nav-contract-address-a2' >
                            <CopyAllIcon style={{color: 'white'}} />
                        </div>
                    </div>    
                
                </div>
                
                {/* Hamburger Navigation */}
                <div className='getStarted-nav-hanburger basis-1/4 ' >
                    <MenuIcon fontSize='large' style={{color: 'white', cursor: 'pointer'}} onClick={handleClick}/>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        
                    >
                        <MenuItem className='hamNav-menu-item' onClick={handleClose}>Account</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={handleClose}>Connect Trust Wallet</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={handleClose}>Support</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={handleClose}>Report</MenuItem>
                        <MenuItem className='hamNav-menu-item' onClick={handleClose}>Log out</MenuItem>
                    </Menu>
                </div>
            </div>



            {/* Heading Area */}
            <div className='getStarted-main-heading' >
                <h2>Be a part of the HMSTR Community</h2>
                <h3>Join the Hamster Pilot Community, perform Airdrop Tasks and win exclusive NFTs and HMSTR Tokens</h3>
            </div>

            {/* Get started button area */}
            <div className='getStarted-main-buttonArea' >
                <div><img src={GetStartedImage1} alt='GetStarted'/></div>
                <div className='getStarted-main-buttonArea-button-container' >
                    <button className='getStarted-main-buttonArea-button' >GET STARTED</button>
                </div>
                <div><img src={GetStartedImage2} alt='GetStarted'/></div>
            </div>
        </div>
    )
}
export default GetStarted;