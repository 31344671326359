import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'


import Home from './Pages/Home'

const App = ()=> {
  return (
    <div className="App">

        <Router>
          <Routes>
            {/* Non-protected routes */}
          <Route 
            path='/*'
            element={
                <Routes>

                  {/* Managing the undefined routes */}
                  <Route path='*' element={<Navigate to='/' />} />
                
                  <Route element={<Home/>} path='/' />
                  
                </Routes>
            } 

          />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
