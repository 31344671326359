import './Footer.css'


const Footer = () => {
    return(
        <div className='footer-main' >
            <h2>&copy; 2024 by Hamster Pilot. Powered and secured by pancakeswap</h2>
        </div>
    )
}
export default Footer;