import './AirdropTask.css'
import TaskWidget from '../../Common/TaskWidget/TaskWidget';

const AirdropTask = () => {
    return(
        <div className='airdropTask-main' >
            <div className='airdropTask-main-head' >
                <h2>Complete Airdrop Tasks</h2>
                <h4>Complete Airdrop Tasks to get exclusive NFT and HMSTR Tokens</h4>
            </div>
            <div className='airdopTask-main-tasks' >
                <TaskWidget title='Create Account and connect Trust Wallet' />
                <TaskWidget title='Trade minimum 10$ Hamster Pilot (HMSTR) Token in your wallet' />
                <TaskWidget title='Join our official Telegram Channel and Share it' />
                <TaskWidget title='Follow our official Twitter page and Like, Retweet and Comment in our posts' />
                <TaskWidget title='Follow our Instagram Account and Share it' />
                <TaskWidget title='Refer Hamster Pilot to a minimum of 5 friends' />
            </div>
        </div>
    )
}
export default AirdropTask;