import './TaskWidget.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TaskWidget = ({title}) => {
    return(
        <div className='taskWidget-main' >
            <h2>{title}</h2>
            <ArrowForwardIosIcon/>
        </div>
    )
}
export default TaskWidget;