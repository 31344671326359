import './Roadmap.css'
import RoadmapImage1 from '../../../Assets/Home/RoadmapImage1.jpeg'

const Roadmap = () => {
    return(
        <div className="roadmap-main" >
            <div className="roadmap-container" >
                <div className="roadmap-container-left" >
                    <h2>Roadmap</h2>
                    <div className='roadmap-list' >
                        <h4>Phase 1: Project Launch</h4>
                        <ul>
                            <li>Smart contract deployment and Liquidity</li>
                            <li>Public token sale and exchange listings</li>
                            <li>Launch of Hamster Pilot (HMSTR) website and community channels </li>
                            <li>Initial marketing and awareness campaigns</li>
                        </ul>
                    </div>
                    <div className='roadmap-list' >
                        <h4>Phase 2: Utility Development</h4>
                        <ul>
                            <li>Release of Meme Marketplace (NFTs)</li>
                            <li>Introduction of staking and yield farming mechanisms </li>
                            <li>Governance system integration (DAO)</li>
                        </ul>
                    </div>
                    <div className='roadmap-list' >
                        <h4>Phase 3: Expansion</h4>
                        <ul>
                            <li>Partnerships with meme creators and influencers </li>
                            <li>Development of Meme Betting Platform</li>
                            <li>Mobile app launch and continued ecosystem development</li>
                        </ul>
                    </div>
                </div>
                <div className="roadmap-container-right" >
                    <img src={RoadmapImage1} alt='RoadmapImage' />
                </div>
            </div>
        </div>
    )
}
export default Roadmap