import './JoinNow.css'
import HeaderImage1 from '../../../Assets/Home/HeaderImage1.png'

const JoinNow = () => {
    return(
        <div className='joinNow-main' >
            <h2>Join the community now!</h2>
            <h4>Join us in the HMSTR journey</h4>
            <img  src={HeaderImage1} alt='HeaderImage' />
            <button>GET STARTED</button>
        </div>
    )
}
export default JoinNow;