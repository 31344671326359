import GetStarted from "../Components/Home/GetStarted/GetStarted";
import Roadmap from "../Components/Home/Roadmap/Roadmap";

import AirdropTask from "../Components/Home/AirdropTask/AirdropTask";
import JoinNow from "../Components/Home/JoinNow/JoinNow";
import Footer from "../Components/Home/Footer/Footer";

const Home = () =>{
    return(
        <div>
            
            <GetStarted/>
            <Roadmap/>
            <AirdropTask/>
            <JoinNow/>
            <Footer/>
        </div>
    )
}
export default Home;